<template>
  <div class="caseAdd">
    <CaseEdit ref="caseEdit" :picNo="picNo">
      <div class="flex" slot="form_submit">
        <el-button type="primary" @click="addCase" :loading="saveLoad"
          >新增案例</el-button
        >
      </div>
      <div class="flex" slot="ditor_submit">
        <el-button type="primary" @click="saveDitor" :loading="saveDitorLoad"
          >保存详情</el-button
        >
      </div>
      <div class="flex" slot="seo_submit">
        <el-button type="primary" @click="saveSeo" :loading="saveSeoLoad"
          >保存TDK</el-button
        >
      </div>
    </CaseEdit>
  </div>
</template>

<script>
import CaseEdit from "./modules/CaseEdit.vue";
import api from "@/api/case.js";
import utils from "@/utils/utils.js";
export default {
  components: {
    CaseEdit,
  },
  data() {
    // 获取随机生成的图片编号
    let picNo = utils.UUID(
      JSON.parse(localStorage.getItem("userInfo")).companyId + "_"
    );
    return {
      picNo: picNo,
      caseNo: "",
      // 按钮状态
      saveLoad: false,
      saveDitorLoad: false,
      saveSeoLoad: false,
    };
  },
  methods: {
    // 新增案例
    async addCase() {
      // 获取案例信息
      let ruleForm = this.$refs.caseEdit.ruleForm;
      let cateList = this.$refs.caseEdit.cateList;
      if (!ruleForm.name) {
        this.$message({
          type: "error",
          message: "请填写案例名称！",
          duration: 800,
        });
        return;
      }
      if (!ruleForm.desc) {
        this.$message({
          type: "error",
          message: "请填写案例描述！",
          duration: 800,
        });
        return;
      }
      if (!ruleForm.pic) {
        this.$message({
          type: "error",
          message: "请上传缩略图！",
          duration: 800,
        });
        return;
      }
      if (cateList != [] && !ruleForm.cateNo) {
        this.$message({
          type: "error",
          message: "请选择分类！",
          duration: 800,
        });
        return;
      }
      this.saveLoad = true;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        name: ruleForm.name,
        pic: ruleForm.pic,
        cateNo: ruleForm.cateNo,
        desc: ruleForm.desc,
        cateName: ruleForm.cateName,
        isShow: false,
        isTop: false,
        isHide: true,
      };
      try {
        const res = await api.addCase(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
            duration: 1000,
          });
          this.caseNo = res.data.result.caseNo;
        } else {
          this.$message.error("网络故障！");
        }
        this.saveLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveLoad = false;
      }
    },
    // 保存案例详情
    async saveDitor() {
      this.saveDitorLoad = true;
      // 获取案例信息
      let ruleForm = this.$refs.caseEdit.ruleForm;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        caseNo: this.caseNo,
        ditorHtml: JSON.stringify(ruleForm.ditorHtml),
      };
      try {
        const res = await api.updateCase(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          });
        } else {
          this.$message.error("网络故障！");
        }
        this.saveDitorLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveDitorLoad = false;
      }
    },
    // 保存案例seo信息
    async saveSeo() {
      this.saveSeoLoad = true;
      // 获取案例信息
      let ruleForm = this.$refs.caseEdit.ruleForm;
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        caseNo: this.caseNo,
        seoTitle: ruleForm.seoTitle,
        seoKeyword: ruleForm.seoKeyword,
        seoDesc: ruleForm.seoDesc,
      };
      try {
        const res = await api.updateCase(condition);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          });
        } else {
          this.$message.error("网络故障！");
        }
        this.saveSeoLoad = false;
      } catch (error) {
        this.$message.error(error);
        this.saveSeoLoad = false;
      }
    },
  },
};
</script>

<style></style>
