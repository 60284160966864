<template>
	<div class="caseEdit">
		<el-tabs v-model="activeName">
			<el-tab-pane label="基本信息" name="first">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="3rem"
				>
					<el-form-item label="案例名称" prop="name">
						<el-input v-model="ruleForm.name" />
					</el-form-item>
					<el-form-item label="案例图片" prop="pic">
						<Upload
							text="必须上传"
							:pic="ruleForm.pic"
							:picName="picNo"
							path="case"
							@uploadSuccess="uploadSuccess"
							@deleteSuccess="deleteSuccess"
						/>
					</el-form-item>
					<el-form-item
						label="案例分类"
						prop="cateNo"
						:required="cateList.length > 0"
					>
						<el-select v-model="ruleForm.cateNo" placeholder="请选择案例分类">
							<el-option
								v-for="item in cateList"
								:key="item.cateNo"
								:label="item.cateName"
								:value="item.cateNo"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="案例描述" prop="desc">
						<el-input
							type="textarea"
							v-model="ruleForm.desc"
							placeholder="请输入案例描述"
						/>
					</el-form-item>
					<el-divider></el-divider>
					<slot name="form_submit"></slot>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="详情介绍" name="second">
				<Editor
					:ditorHtml="ruleForm.ditorHtml"
					imgType="case"
					@updateHtml="updateHtml"
				/>
				<slot name="ditor_submit"></slot>
			</el-tab-pane>
			<el-tab-pane label="TDK管理" name="third">
				<el-form class="caseEdit_tdk" label-width="3rem">
					<el-form-item label="SEO标题">
						<el-input v-model="ruleForm.seoTitle" /> </el-form-item
					><el-form-item label="SEO关键词">
						<el-input
							v-model="ruleForm.seoKeyword"
							placeholder="注：多个关键词用英文“,”隔开"
						/>
					</el-form-item>
					<el-form-item label="SEO描述">
						<el-input
							v-model="ruleForm.seoDesc"
							type="textarea"
							:rows="2"
							style="width: 12rem"
						/> </el-form-item
				></el-form>
				<slot name="seo_submit"></slot>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import Upload from "@/components/Upload.vue";
import Editor from "@/components/Editor.vue";
export default {
	props: {
		ruleForm: {
			type: Object,
			default: function() {
				return {
					name: "",
					pic: "",
					cateNo: "",
					desc: "",
					ditorHtml: "",
					seoTitle: "",
					seoKeyword: "",
					seoDesc: "",
				};
			},
		},
		picNo: {
			type: String,
		},
	},
	data() {
		return {
			activeName: "first",
			rules: {
				name: [{ required: true, message: "请输入案例名称", trigger: "blur" }],
				desc: [{ required: true, message: "请输入案例描述", trigger: "blur" }],
			},
			// 分类列表
			cateList: [],
		};
	},
	methods: {
		// 上传图片成功
		uploadSuccess(val) {
			val.url = val.url + "?" + Math.floor(Math.random() * 100) + 0;
			this.ruleForm.pic = val.url;
		},
		// 删除图片成功
		deleteSuccess() {
			this.ruleForm.pic = "";
		},
		// 更新editor内容
		updateHtml(val) {
			this.ruleForm.ditorHtml = val;
		},
	},
	mounted() {
		this.cateList = this.$store.getters.caseCateList;
	},
	components: {
		Upload,
		Editor,
	},
};
</script>

<style lang="scss">
.caseEdit {
	.el-form {
		margin-top: 20px;
		&-item {
			display: flex;

			.caseEdit_param-title {
				align-items: center;

				> p {
					margin-right: 20px;
					font-weight: bold;
				}
			}
		}
		&-item /deep/ .el-form-item__content {
			margin: 0 !important;
		}
		.el-input,
		.el-textarea {
			width: 500px;
		}
	}

	.caseEdit_tdk {
		.el-input {
			width: 12rem;
		}
	}
}
</style>
