<template>
  <div class="case">
    <TabBar>
      <div class="flex" slot="tabBar_left">
        <el-button
          :type="checkTabIndex == item.id ? 'primary' : ''"
          v-for="item in tabBarList"
          :key="item.id"
          @click="checkTab(item.id)"
          >{{ item.text }}</el-button
        >
      </div>
    </TabBar>
    <div class="case_content">
      <CaseList v-if="checkTabIndex == 0" />
      <CaseAdd v-else-if="checkTabIndex == 1" />
      <CaseCate v-else-if="checkTabIndex == 2" />
    </div>
  </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";
import CaseList from "@/views/case/CaseList.vue";
import CaseAdd from "@/views/case/CaseAdd.vue";
import CaseCate from "@/views/case/CaseCate.vue";
export default {
  name: "Case",
  components: {
    TabBar,
    CaseList,
    CaseAdd,
    CaseCate,
  },
  data() {
    return {
      tabBarList: [
        { text: "案例管理", id: 0 },
        { text: "添加案例", id: 1 },
        { text: "案例分类", id: 2 },
      ],
      checkTabIndex: 0,
    };
  },
  methods: {
    //  选中tab栏
    checkTab(val) {
      this.checkTabIndex = val;
    },
  },
};
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.case {
  padding: 10px;
  &_content {
    background-color: $bgColor;
    border-radius: 10px;
    padding: 20px 40px;
    color: $fontColor;
  }
}
</style>
