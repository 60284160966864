<template>
	<div class="caseCate">
		<div class="caseCate_th flex">
			<p class="th_id">ID</p>
			<p class="th_name">分类名称</p>
			<p class="th_sort">排序</p>
			<p class="th_show">展示</p>
			<p class="th_func">操作</p>
		</div>
		<div v-show="!cateList.length > 0" class="caseCate_error">
			<p>暂无数据</p>
		</div>
		<Draggable :list="cateList">
			<div
				class="caseCate_td flex"
				v-for="(item, index) in cateList"
				:key="index"
				slot="drag_list"
			>
				<p class="td_id">{{ item.cateNo }}</p>
				<p class="td_name">{{ item.cateName }}</p>
				<p class="td_sort">{{ item.sort }}</p>
				<p class="td_show">
					<el-switch
						v-model="item.isShow"
						:active-value="1"
						:inactive-value="0"
					>
					</el-switch>
				</p>
				<p class="td_func">
					<el-button type="text" @click="updateCate(item, index)">
						编辑
					</el-button>
					<el-button
						type="text"
						style="color: red"
						@click="deleteCate(item.cateNo)"
					>
						删除
					</el-button>
				</p>
			</div>
		</Draggable>
		<p class="caseCate_hint">注：拖动可进行排序；操作之后请保存</p>
		<el-button type="primary" @click="addCate">+ 添加案例分类</el-button>
		<el-button type="success" @click="submitCate">保存分类</el-button>
	</div>
</template>

<script>
import Draggable from "@/components/Draggable.vue";
import api from "@/api/case.js";
export default {
	data() {
		return {
			cateList: [],
		};
	},
	components: {
		Draggable,
	},
	methods: {
		// 获取案例分类列表
		async getCate() {
			try {
				const res = await api.getCate();
				if (res.data.code == 200) {
					this.cateList = res.data.result || [];
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 添加分类弹窗
		addCate() {
			this.$prompt("请输入分类名称", "添加分类", {
				confirmButtonText: "添加",
				cancelButtonText: "取消",
			})
				.then(({ value }) => {
					this.submitAddCate(value);
				})
				.catch(() => {});
		},
		async submitAddCate(val) {
			try {
				let condition = {
					companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
					cateName: val,
					sort: 0,
				};
				const res = await api.addCate(condition);
				if (res.data.code == 200) {
					// 重新获取分类列表
					this.getCate();
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 编辑分类
		updateCate(val, index) {
			this.$prompt("请输入分类名称", "修改分类", {
				inputValue: val.cateName,
				confirmButtonText: "修改",
				cancelButtonText: "取消",
			})
				.then(({ value }) => {
					this.cateList[index].cateName = value;
				})
				.catch(() => {});
		},
		// 删除分类
		deleteCate(val) {
			this.$confirm("确认删除该分类吗?", "删除分类", {
				confirmButtonText: "删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.submitDeleteCate(val);
				})
				.catch(() => {});
		},
		async submitDeleteCate(val) {
			try {
				let condition = {
					companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
					cateNo: val,
				};
				const res = await api.deleteCate(condition);
				if (res.data.code == 200) {
					this.$message({
						type: "success",
						message: res.data.message,
						duration: 800,
					});
					// 重新获取分类列表
					this.getCate();
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
		// 保存分类
		async submitCate() {
			try {
				let list = this.cateList;
				list.forEach((item, index) => {
					item.sort = index + 1;
				});
				let condition = {
					companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
					cateList: list,
				};
				const res = await api.updateCate(condition);
				if (res.data.code == 200) {
					this.$message({
						type: "success",
						message: "保存成功！",
						duration: 800,
					});
					// 重新获取分类列表
					this.getCate();
				} else {
					this.$message.error("网络故障！");
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
	mounted() {
		this.cateList = this.$store.getters.caseCateList;
	},
};
</script>

<style lang="scss">
.caseCate {
	text-align: left;
	&_th {
		height: 40px;
		width: 800px;
		background-color: #f5f5f5;
		align-items: center;
		padding-left: 40px;
		font-weight: bold;

		> p {
			width: 20%;
		}
	}

	&_td {
		height: 40px;
		width: 800px;
		line-height: 40px;
		padding-left: 40px;
		border-left: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;
		border-bottom: 1px solid #e8e8e8;

		> p {
			width: 20%;
		}
	}

	&_error {
		height: 40px;
		width: 800px;
		line-height: 40px;
		text-align: center;
		border: 1px solid #e8e8e8;
		color: #999999;
	}

	&_hint {
		margin: 20px 0;
		color: #999999;
		text-align: left;
	}
}
</style>
