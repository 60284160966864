<template>
  <div class="caseList">
    <div class="caseList_search flex">
      <el-input
        placeholder="搜索案例名称"
        suffix-icon="el-icon-search"
        v-model="search.caseName"
        style="width: 6rem;"
      ></el-input>

      <el-select
        v-model="search.cateNo"
        placeholder="选择分类"
        style="width: 4rem;"
      >
        <el-option label="未分类" value="0"> </el-option>
        <el-option
          v-for="item in cateList"
          :key="item.cateNo"
          :label="item.cateName"
          :value="item.cateNo"
        >
        </el-option>
      </el-select>

      <el-button type="primary" @click="getCaseList">搜索</el-button>
    </div>

    <div class="caseList_table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%;border: 1px solid #e8e8e8"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="handleSelectionChange"
        @row-dblclick="caseDetail"
        ><el-table-column
          type="selection"
          :selectable="checkSelectable"
          width="55"
        >
        </el-table-column>

        <el-table-column prop="name" label="案例名称">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.name"
            >
              <p class="table_font" slot="reference">{{ scope.row.name }}</p>
            </el-popover></template
          >
        </el-table-column>
        <el-table-column label="案例图片">
          <template slot-scope="scope">
            <img
              v-show="scope.row.caseNo"
              style="width: 100px;height: 100px"
              :src="scope.row.pic"
            /> </template
        ></el-table-column>
        <el-table-column prop="cateName" label="案例分类"> </el-table-column>
        <el-table-column label="录入时间">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.createTime"
            >
              <p class="table_font" slot="reference">
                {{ scope.row.createTime }}
              </p>
            </el-popover></template
          >
        </el-table-column>
        <el-table-column label="案例简述">
          <template slot-scope="scope">
            <p class="table_font">
              {{ scope.row.desc }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="百度优化">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoTitle"
              v-show="scope.row.caseNo"
            >
              <p class="table_font" slot="reference">
                SEO标题：{{ scope.row.seoTitle }}
              </p>
            </el-popover>
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoKeyword"
              v-show="scope.row.caseNo"
            >
              <p class="table_font" slot="reference">
                关键词：{{ scope.row.seoKeyword }}
              </p>
            </el-popover>
            <el-popover
              placement="right"
              width="200"
              trigger="hover"
              :content="scope.row.seoDesc"
              v-show="scope.row.caseNo"
            >
              <p class="table_font" slot="reference">
                描述：{{ scope.row.seoDesc }}
              </p>
            </el-popover></template
          >
        </el-table-column>

        <el-table-column label="上架">
          <template slot-scope="scope">
            <el-switch
              v-show="scope.row.caseNo"
              v-model="scope.row.isShow"
              active-color="#009688"
              @change="
                (val) => {
                  putaway(val, scope.row)
                }
              "
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="置顶">
          <template slot-scope="scope">
            <i
              v-show="scope.row.caseNo"
              class="el-icon-download"
              :class="{ active: scope.row.isTop }"
              @click="stickyCheck(scope.row)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row.caseNo"
              type="text"
              @click="updateCaseInfo(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TableFunc>
      <div class="caseList_func flex" slot="tableFunc_list">
        <el-button type="danger" size="small" @click="lose">失效</el-button>
        <el-button size="small" @click="dialogVisible = true"
          >失效案例</el-button
        >
      </div>
    </TableFunc>
    <el-pagination
      class="pagination flex"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <CaseLose :dialogVisible="dialogVisible" @dialogClose="dialogClose" />

    <el-dialog
      title="编辑案例"
      destroy-on-close
      :visible.sync="dialogFormVisible"
      @close="closeUpdate"
    >
      <CaseEdit
        v-if="dialogFormVisible"
        :ruleForm="updateInfo"
        :picNo="picNo"
        ref="caseEdit"
      >
        <div class="flex" slot="form_submit">
          <el-button type="primary" @click="submitUpdate" :loading="saveLoad"
            >保存修改</el-button
          >
        </div>
        <div class="flex" slot="ditor_submit">
          <el-button type="primary" @click="submitUpdate" :loading="saveLoad"
            >保存修改</el-button
          >
        </div>
        <div class="flex" slot="seo_submit">
          <el-button type="primary" @click="submitUpdate" :loading="saveLoad"
            >保存修改</el-button
          >
        </div></CaseEdit
      >
    </el-dialog>
  </div>
</template>

<script>
import TableFunc from "@/components/TableFunc.vue"
import CaseLose from "./modules/CaseLose.vue"
import CaseEdit from "./modules/CaseEdit.vue"
import api from "@/api/case.js"
import utils from "@/utils/utils.js"
export default {
  name: "CaseList",
  components: { TableFunc, CaseLose, CaseEdit },
  data() {
    return {
      // 搜索
      search: {
        caseName: "",
        cateNo: "",
      },
      // 分类列表
      cateList: [],
      // 表单数据
      tableData: [],
      // 多选
      multipleSelection: [],
      rows: 5,
      page: 1,
      total: 0,
      dialogVisible: false,
      // 编辑案例弹窗
      dialogFormVisible: false,
      // 修改案例的信息
      updateInfo: {},
      // 需要修改的案例图片的编号
      picNo: "",
      saveLoad: false,
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.rows = val
      this.getCaseList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getCaseList()
    },
    // 获取案例列表
    async getCaseList() {
      try {
        let condition = {
          pageInfo: {
            rows: this.rows,
            page: this.page,
          },
          reqData: {
            companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
            name: this.search.caseName,
            cateNo: this.search.cateNo,
            isHide: 1,
          },
        }
        const res = await api.getCase(condition)
        if (res.data.code == 200) {
          if ((res.data.result.list || []).length > 0) {
            let list = res.data.result.list
            list.forEach((item) => {
              item.ditorHtml = JSON.parse(item.ditorHtml)
              item.createTime = utils.format(
                item.createTime,
                "yyyy-MM-dd HH:mm:ss"
              )
              item.isShow = item.isShow ? true : false
              item.isTop = item.isTop ? true : false
              item.isHide = item.isHide ? true : false
              this.cateList.forEach((item2) => {
                if (item2.cateNo == item.cateNo) {
                  item.cateName = item2.cateName
                }
              })
            })
            // 数量不足，补齐
            for (let i = 1, num = this.rows - list.length; i <= num; i++) {
              list.push({})
            }
            this.tableData = list
            this.total = res.data.result.total
          } else {
            this.tableData = []
            this.total = 0
          }
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 获取案例分类列表
    async getCate() {
      try {
        const res = await api.getCate()
        if (res.data.code == 200) {
          this.cateList = res.data.result || []
          this.$store.commit("setCaseCateList", res.data.result || [])
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 案例详情
    caseDetail(row) {
      console.log(row)
    },
    // 删除案例
    lose() {
      if (!this.multipleSelection.length > 0) {
        this.$message({
          type: "error",
          message: "未选择案例!",
          duration: 800,
        })
        return
      }
      this.$confirm("失效之后不可恢复，确认失效吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let flag = []
          for (let i = 0, n = this.multipleSelection.length; i < n; i++) {
            let res = this.update(this.multipleSelection[i])
            flag.push(res)
          }
          if (
            flag.every(function(item) {
              return item
            })
          ) {
            this.$message({
              type: "success",
              message: "失效成功!",
            })
            this.getCaseList()
          } else {
            this.$message.error("操作失败！")
          }
        })
        .catch(() => {})
    },
    async update(val) {
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        caseNo: val.caseNo,
        isHide: false,
      }
      try {
        const res = await api.updateCase(condition)
        if (res.data.code == 200) {
          return true
        } else {
          this.$message.error("网络故障！")
          return false
        }
      } catch (error) {
        this.$message.error(error)
        return false
      }
    },
    // 置顶
    async stickyCheck(val) {
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        caseNo: val.caseNo,
        isTop: !val.isTop,
      }
      try {
        const res = await api.updateCase(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: !val.isTop ? "置顶成功！" : "取消成功！",
            duration: 800,
          })

          this.tableData.forEach((item) => {
            if (item.caseNo == val.caseNo) {
              item.isTop = !val.isTop
            }
          })
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 案例上下架
    async putaway(flag, val) {
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        caseNo: val.caseNo,
        isShow: flag,
      }
      try {
        const res = await api.updateCase(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: flag ? "上架成功！" : "下架成功！",
            duration: 800,
          })
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 编辑案例信息
    updateCaseInfo(val) {
      // 截取图片id
      let url = val.pic
      url = url.split("?")[0]
      let picNo = url.split("/").pop()
      picNo = picNo.split(".")[0]

      this.updateInfo = val
      this.picNo = picNo
      this.dialogFormVisible = true
    },
    // 关闭编辑案例信息弹窗
    closeUpdate() {
      this.updateInfo = {}
      this.dialogFormVisible = false
    },
    // 提交修改
    async submitUpdate() {
      const that = this
      that.saveLoad = true
      // 获取案例信息
      var condition = JSON.parse(JSON.stringify(that.$refs.caseEdit.ruleForm))
      delete condition.createTime
      delete condition.updateTime
      delete condition.id
      delete condition.cateName
      condition.ditorHtml = JSON.stringify(condition.ditorHtml)
      try {
        const res = await api.updateCase(condition)
        if (res.data.code == 200) {
          that.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          })
          this.getCaseList()
        } else {
          that.$message.error("网络故障！")
        }
        that.saveLoad = false
      } catch (error) {
        that.$message.error(error)
        that.saveLoad = false
      }
    },
    // 禁止选中
    checkSelectable(row) {
      return row.caseNo
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 关闭失效案例弹窗
    dialogClose() {
      this.dialogVisible = false
    },
  },
  mounted() {
    this.getCate()
    this.getCaseList()
  },
}
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.caseList {
  &_search {
    align-items: center;

    > div {
      margin-right: 20px;
    }
  }

  &_table {
    margin: 20px 0;
  }

  &_func {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 10px;
  }

  .pagination {
    justify-content: flex-end;
  }

  .el-icon-download {
    font-size: 26px;
    font-weight: bold;
    color: $fontShallow;
    transform: rotate(180deg);
    cursor: pointer;

    &.active {
      color: #d11818;
    }
  }
}
</style>
