import request from "@/utils/request.js"

export default {
  // 获取案例
  getCase(data) {
    return request({
      url: "/case/getCase",
      method: "post",
      data: data,
    })
  },
  // 添加案例
  addCase(data) {
    return request({
      url: "/case/addCase",
      method: "post",
      data: data,
    })
  },
  // 更新案例信息
  updateCase(data) {
    data.address = JSON.parse(localStorage.getItem("userInfo")).address
    data.company = JSON.parse(localStorage.getItem("userInfo")).company
    return request({
      url: "/case/updateCase",
      method: "post",
      data: data,
    })
  },
  // 获取案例分类
  getCate() {
    return request({
      url: "/case/getCate?companyId=" + JSON.parse(localStorage.getItem("userInfo")).companyId,
      method: "get",
    })
  },
  // 添加案例分类
  addCate(obj) {
    return request({
      url: "/case/addCate",
      method: "post",
      data: obj,
    })
  },
  // 修改案例分类
  updateCate(obj) {
    return request({
      url: "/case/updateCate",
      method: "post",
      data: obj,
    })
  },
  // 删除案例分类
  deleteCate(obj) {
    return request({
      url: "/case/deleteCate",
      method: "post",
      data: obj,
    })
  },
}
